<template>
  <div class="relative flex h-full w-full items-center justify-center">
    <BaseLoader v-if="loading" />
    <NoDataFound v-else-if="noChart" />
    <BaseLineChart v-else :options="chartOptions" :series="chartSeries" />
  </div>
</template>
<script setup>
import useEmitter from '@/composeables/emitter';
import { formatNumber } from '@/composeables/filters';
import { usdCryptoPrice } from '@/composeables/filters';
import colors from 'tailwindcss/colors';
import { ref, computed, nextTick, watch, onMounted } from 'vue';
import BaseLineChart from '@/components/chart/BaseLineChart.vue';
import isEmpty from 'lodash/isEmpty';

const $emitter = useEmitter();

const props = defineProps({
  series: { type: Object, default: () => ({}) },
  loading: { type: Boolean, default: false }
});
const chartId = ref(`price-mkt-${Math.random()}`);

onMounted(() => {
  $emitter.$on('reflow-highchart', () => {
    resetChart();
  });
});
const noChart = computed(() => {
  return !props.loading && isEmpty(props.series);
});
const priceSeries = computed(() => {
  return props.series.price ? props.series.price : [];
});
const sentimentSeries = computed(() => {
  return props.series.sentiment ? props.series.sentiment : [];
});
const event = computed(() => {
  return props.series.event ? props.series.event : [];
});
const tweetVolumeSeries = computed(() => {
  return props.series.tweet_volume ? props.series.tweet_volume : [];
});

const chartOptions = computed(() => {
  return {
    chart: {
      id: chartId.value
    },
    xAxis: {
      type: 'datetime',
      title: { text: '' },
      plotLines: [
        {
          color: colors['blue']['700'],
          width: 2,
          value: event.value,
          zIndex: 5
        }
      ],
      labels: {
        style: {
          color: colors['gray']['400']
        }
      }
    },
    yAxis: [
      {
        title: {
          text: (tweetVolumeSeries.value || []).length ? 'Tweet Volume' : '',
          style: { color: colors['gray']['400'] }
        },
        labels: {
          style: { color: colors['gray']['400'] },
          formatter() {
            const formattedVal = this.value;
            if (this.axis.series[0].name == 'Market Cap') {
              return formatNumber(this.value, 5);
            }
            return formattedVal;
          }
        }
      },
      {
        title: {
          text: 'Price',
          style: { color: colors['blue']['400'] }
        },
        labels: {
          style: { color: colors['blue']['400'] }
        },
        opposite: true
      },
      ...((sentimentSeries.value || []).length
        ? [
            {
              gridLineWidth: 0,
              title: {
                text: 'Sentiment',
                style: { color: colors['red']['400'] }
              },
              labels: {
                style: { color: colors['red']['400'] }
              },
              opposite: true
            }
          ]
        : [])
    ],
    legend: { enabled: false },
    tooltip: {
      pointFormatter: function () {
        let y = this.y;
        if (['Market Cap', 'Price'].includes(this.series.name)) {
          y = usdCryptoPrice(this.y);
        } else {
          y = Math.round(this.y);
        }
        return `<div class="">
                  <div class="flex items-center w-full space-x-1">
                  <span style="color:${this.color}">●</span>
                  <span class="text-xxs text-gray-400">${this.series.name}</span><br />
                </div>
                <div>
                  <span class=" text-gray-200">${y}</span>
                </div>
                </div>`;
      }
    }
  };
});

const chartSeries = computed(() => {
  return [
    {
      data: tweetVolumeSeries.value,
      name: 'Tweet Volume',
      yAxis: 0,
      color: colors.gray[400],
      opacity: 0.4,
      borderColor: colors.gray[400],
      type: 'column',
      zIndex: 0
    },
    {
      data: priceSeries.value.length ? priceSeries.value : props.series.market_cap,
      name: priceSeries.value.length ? 'Price' : 'Market Cap',
      color: colors.blue[400],
      type: 'spline',
      lineWidth: 3,
      yAxis: 1,
      zIndex: 2
    },
    ...((sentimentSeries.value || []).length
      ? [
          {
            type: 'spline',
            data: sentimentSeries.value,
            name: 'Sentiment',
            yAxis: 2,
            lineWidth: 2,
            color: colors.green[400],
            negativeColor: colors.red[400],
            threshold: 50,
            zIndex: 1
          }
        ]
      : [])
  ];
});

watch(
  () => props.series,
  () => {
    resetChart();
  }
);
function resetChart() {
  nextTick(() => {
    chartId.value = `price-mkt-${Math.random()}`;
  });
}
</script>
